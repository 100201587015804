/* eslint-disable max-len */

import { Website } from '../types';
import { mapDirectusMetadata, mapDirectusPage, mapDirectusPress, mapDirectusTvDevice, MappingDirectusPage, MappingPageContent } from './mapping';

import { DirectusPage } from 'config';
import websiteContent from 'config/xumo-website-output.json';
import devices from 'ui/devices.json';
import * as directusApi from 'config/utils/directusApi';

type WebsiteOptions = Website.Corporate | Website.Enterprise | Website.Shopper;

export interface GetGeneralPageStaticPropsArgs {
  website: WebsiteOptions;
}

export type GetGeneralPageStaticPropsParams = {
  slug: string[] | undefined;
  website: WebsiteOptions;
};

export type GetGeneralPageMetadataResponse = {
  title: string;
  description: string;
  image: string;
  url: string;
  host: WebsiteOptions;
};

export type GetGeneralPageStaticPropsResponse = {
  locale: string;
  page: MappingDirectusPage;
  metadata: GetGeneralPageMetadataResponse;
  cssVariables?: string;
};

export type GetGeneralPageStaticPropsContext = {
  params: GetGeneralPageStaticPropsParams;
  locale: string;
};

export const getPageContent = (data: DirectusPage[], slug: string[] | null) => {
  return data.find((page: DirectusPage) => (slug === null ? page.slug === null : page.slug === slug.join('/')));
};

const getDeviceContent = slug => {
  const deviceId = devices.findIndex(device => {
    const heading = device.heading.toLowerCase()
    const slugComp = slug[2]
      .split('-')
      .map(word => !Number.isNaN(Number(word)) ? '$size' : word)
      .join(' ')
  
    return heading === slugComp
  })

  return { 
    slug,
    device: {
      ...devices[deviceId],
      deviceId
    },
    size: slug[2].split('-').find(word => !Number.isNaN(Number(word)))
  }
}

export const getGeneralPageStaticProps =
  ({ website }: GetGeneralPageStaticPropsArgs) =>
  async ({ params, locale, preview }: GetGeneralPageStaticPropsContext): Promise<{ props: GetGeneralPageStaticPropsResponse }> => {
    const slug = params?.slug || null;
    const isPress = (slug?.length || 0) > 1 && (slug as string[])[0] === 'press';
    const isTvDevice = (slug?.length || 0) === 3 && (slug as string[])[0] === 'products' && (slug as string[])[1] === 'xumo-tv' && (slug as string[])[2] !== 'search';

    let data
    console.log('env', process.env.NODE_ENV)
    // if (process.env.NODE_ENV === 'development' || preview) {
  //   data = await directusApi.getPage(slug ? slug.join('/') : '')
    //   console.log(data)
    // } else {
      data = isTvDevice
        ? getDeviceContent(slug)
        : getPageContent(
          isPress 
            ? (websiteContent as any).press 
            : (websiteContent as any).content,
            slug
        );
    //}

    const page = isPress
      ? mapDirectusPress(data as Omit<MappingPageContent, 'collection'>, (websiteContent as any).settings)
      : isTvDevice 
        ? mapDirectusTvDevice(data as MappingDirectusPage, (websiteContent as any).settings)
        : mapDirectusPage(data as MappingDirectusPage, (websiteContent as any).settings);

    const metadata = mapDirectusMetadata(data as MappingDirectusPage, isPress);

    console.log('Rendering', locale, slug)
    console.log('isTvDevice', isTvDevice)

    return {
      props: {
        // hack to get around next.js complaining about undefined values
        page: JSON.parse(JSON.stringify(page)),
        metadata,
        locale: locale ?? 'en',
      },
    };
  };
