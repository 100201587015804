/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable max-len */
import { motion, useTransform } from 'framer-motion';
import { useRef, useState, useEffect } from 'react';
import { useInViewScroll, MotionImage, useSanitizedTranslation, StackedContent } from 'ui';

export const PromoBlock = ({ content, bg }) => {
    const { t } = useSanitizedTranslation();
    const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
    const scrollY = useTransform(amt, [0, 1], [-200, 0]);
    
    return false
    return <motion.section ref={ref} className="relative z-40 bg-white w-full flex justify-center" data-testid={'promo-section'}>
      <div className="wrapper">
        <div className={`relative overflow-hidden flex max-lg:flex-col rounded-3xl my-16`}>
          <div className="relative max-lg:h-[350px] lg:absolute top-0 left-0 w-full h-full">
            <MotionImage
              style={{y: scrollY }}
              src={'/static/images/refresh/belair-promo.jpg'}
              alt=""
              fill
              className="object-cover w-full max-lg:object-[85% 50%] h-[calc(100%+200px)] brightness-90"
              sizes='100vw' />
          </div>
          <div style={{background: 'linear-gradient(to right, #0009 25%, #0006 65%, #0000 100%'}} className="max-lg:hidden w-full h-full absolute top-0 left-0" />
          <div className="max-lg:bg-gradient-to-r max-lg:from-[#140D13] max-lg:to-[#5B2E4F] lg:w-[55%] relative z-10">
            <div className="content-wrapper">
              <StackedContent
                light
                content={[
                  { image: {
                    src: '/static/images/refresh/peacock-logo.png',
                    alt: "",
                    width: 590,
                    height: 186,
                    renderedHeight: 186/2
                  } },
                  { heading: 'Get 6 months of Peacock Premium <strong>at no extra cost</strong>' },
                  { body: 'Discover new movies, enjoy your favorite comfort watches, tune into live sports and more. '},
                  { 
                    ctaRow : [
                      {
                        light: true,
                        label: 'Subscribe now',
                        href: '/subscriptions',
                      }
                    ] 
                  }
              ]} />
              <p className="max-lg:text-center type-body text-[12px] text-white mt-16 lg:w-4/5">Limited time offer. $7.99/mo (+tax) after offer ends. Cancel anytime. Eligibility terms apply: www.peacocktv.com/xumooffer. Peacock © Peacock TV LLC. All rights reserved. All other programs and/or marks are the property of their respective owners.</p>
            </div>
          </div>
        </div>
      </div>
    </motion.section>
  }

  // <MotionImage
            //     src={'/static/images/refresh/peacock-logo.png'}
            //     alt=""
            //     width={590/2}
            //     height={186/2}
            //     className="mb-8"
            //     sizes='100vw' />
            // <h2 className="type-heading weight-300 text-white pb-8"></h2>
            // <p className="type-body leading-tight text-white mb-4"></p>
            // <Button light>Subscribe now</Button>